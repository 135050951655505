.grid {
	--size: 30px;
	position: relative;
	display: grid;
	gap: 2px;
	padding: 2px;
	border-radius: 5px;
	background-color: #ffff;
	overflow: hidden;

	& > .row {
		display: grid;
		gap: 2px;
		height: var(--size);

		& > .cell {
			display: flex;
			justify-content: center;
			align-items: center;
			width: var(--size);
			border: none;
			background-color: #f6f6f6;
			font-weight: 700;
			outline: none;

			&.hidden {
				background-color: #dedede;
				cursor: pointer;
			}

			&.v1 {
				color: blue;
			}

			&.v2 {
				color: green;
			}

			&.v3 {
				color: orange;
			}

			&.v4 {
				color: red;
			}

			&.v5 {
				color: purple;
			}

			& > img {
				width: 70%;
				height: 70%;
				object-fit: cover;
			}
		}

		&:first-child > .cell {
			&:first-child {
				border-top-left-radius: 5px;
			}

			&:last-child {
				border-top-right-radius: 5px;
			}
		}
		&:last-child > .cell {
			&:first-child {
				border-bottom-left-radius: 5px;
			}

			&:last-child {
				border-bottom-right-radius: 5px;
			}
		}
	}

	& > .overlay {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 30px;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.3);
		backdrop-filter: blur(1px);

		& > .overlayTitle {
			font-size: 2.5em;
			color: var(--overlayColor);
		}

		& > .overlayButton {
			display: grid;
			grid-template-columns: 1.1em auto;
			place-items: center;
			gap: 10px;
			padding: 10px 25px;
			border: none;
			border-radius: 5px;
			background-color: var(--overlayColor);
			font-size: 1em;
			color: #ffff;
			cursor: pointer;
		}
	}
}
