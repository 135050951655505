.gameContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	gap: 30px;
	width: 95svw;
	max-width: 500px;

	& > .title {
		margin: 0;
		font-size: 2em;
		font-weight: 600;
		text-align: center;
		color: #ffffff;
	}

	& > .settingsContainer {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 20px;
		border: 2px solid #f39440;
		border-radius: 5px;
		background-color: #ffdccb;

		& > .selectorBox {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 20px;

			& > .settingLabel {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				gap: 5px;
				font-size: 1.2rem;
				color: #f39440;

				& > svg {
					width: 1.2em;
				}
			}

			& > .select {
				padding: 2.5px 5px;
				border: 2px solid #f39440;
				border-radius: 5px;
				background-color: #ffdccb;
				font-size: 1rem;
				font-weight: 400;
				color: #f39440;
				outline: none;
				cursor: pointer;
			}
		}

		& > .startButton {
			padding: 10px;
			border: 2px solid #f39440;
			border-radius: 5px;
			background-color: #ffdccb;
			font-size: 1.2em;
			color: #f39440;
			cursor: pointer;
			transition: background-color 0.3s ease, color 0.3s ease;

			&:hover {
				background-color: #f39440;
				color: #ffdccb;
			}
		}
	}
}
