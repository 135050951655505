.statContainer {
	display: grid;
	grid-template-columns: 25px 40px;
	align-items: center;
	gap: 10px;
	padding: 10px;
	border-radius: 5px;
	background-color: #ffdccb;
	font-weight: 600;
	color: #f39440;
}
