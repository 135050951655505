.title {
	margin-bottom: 40px;
	font-size: 2rem;
	font-weight: 600;
	color: #ffffff;
}

.board {
	display: flex;
	flex-direction: column;
	width: 95svw;
	max-width: 600px;
	border-radius: 5px;
	background-color: #fff;
	overflow: hidden;

	& > .header {
		display: grid;
		grid-template-columns: 1em 1fr 1fr 0.8fr 1fr;
		gap: 20px;
		padding: 15px 20px;
		background-color: #333;

		& > * {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			gap: 10px;
			font-size: 1.1em;
			font-weight: 400;
			color: #fff;

			& > svg {
				height: 1.2em;
			}

			&:last-child {
				justify-content: flex-end;
			}
		}
	}

	& > .scoresContainer {
		height: 100%;
		max-height: 400px;
		overflow-y: auto;

		& > .score {
			display: grid;
			grid-template-columns: 1em 1fr 1fr 0.8fr 1fr;
			align-items: center;
			justify-content: center;
			gap: 20px;
			padding: 10px 20px;
			background-color: #e1e1e1;
			color: #333;

			& > * {
				&:first-child {
					opacity: 0.5;
				}

				&:last-child {
					text-align: right;
					font-weight: 600;
				}
			}

			&:nth-child(2n) {
				background-color: #ffffff;
			}
		}
	}
}

.controls {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 10px;
	width: 100%;

	& > .controlButton {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		gap: 10px;
		padding: 10px;
		border: none;
		border-radius: 5px;
		font-size: 16px;
		font-weight: 600;
		transition: filter 0.3s ease;
		color: #fff;
		cursor: pointer;

		&:hover {
			filter: brightness(1.1);
		}

		&.home {
			background-color: #f39440;
		}

		&.replay {
			background-color: #f39440;
		}

		&.clear {
			background-color: #ca5940;
		}

		&.clearAll {
			background-color: hsl(0, 57%, 52%);
		}

		& > svg {
			width: 20px;
		}
	}
}
